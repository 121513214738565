import { useCallback, useEffect } from 'react'

import { Loader } from '@mantine/core'

import { useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

const VoELoginPage = () => {
  const { logIn } = useAuth()
  const { redirectTo } = useParams()
  const redirectUri = window.location.origin + redirectTo

  const onLogin = useCallback(async () => {
    await logIn({
      authorizationParams: {
        redirect_uri: redirectUri,
        connection: 'email',
      },
      appState: {
        targetUrl: redirectUri,
      },
    })
  }, [logIn, redirectUri])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onLogin()
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [onLogin])

  return (
    <>
      <div className="flex min-h-screen items-center justify-center">
        <Loader />
      </div>
    </>
  )
}

export default VoELoginPage
